<template>
  <div>
    <div class="row" v-for="(i, key) in value" :key="key">
      <UploadField v-model="value[key]" @input="uploadFile">
        <a @click="deleteRow(key)" class="deleteLine" v-translate>delete</a>
      </UploadField>
    </div>
    <div class="row">
      <a @click.prevent="addRow" class="addmore" :class="{'addmore--disabled': !canAddRow}" v-translate>Add file +</a>
    </div>
  </div>
</template>

<script>
import UploadField from "./upload_field"

export default {
  props: ['value'],
  components: {
    UploadField,
  },
  computed: {
    canAddRow() {
      if (this.value[this.value.length - 1] || this.value.length === 0) {
        return true
      }
      return false
    },
  },
  methods: {
    addRow() {
      if (this.canAddRow) {
        this.value.push(null)
      }
    },
    deleteRow(i) {
      this.value.splice(i, 1)
      this.$emit('input', this.value)
    },
    uploadFile(e) {
      e.forEach(d => {
            this.value.push(d)
          }
      )
      this.$emit('input', this.value.filter(d => d.name))
    },
    uploadHandler(event) {
      event.preventDefault()

    }
  },
  mounted() {
    window.addEventListener('drop', this.uploadHandler)
    window.addEventListener('dragover', this.uploadHandler)
  },
  beforeDestroy() {
    window.removeEventListener('drop', this.uploadHandler)
    window.removeEventListener('dragover', this.uploadHandler)
  }
}

</script>

<style>
.deleteLine {
  flex: 1 0 auto;
}
</style>