<template>
    <form method="get" :action="backend_url + '#search_members_results'">
        <div class="row">
            <div>
                <input v-model="location" type="text" name="location" :placeholder="$gettext('city or postcode')">
                <ul v-if="location && location_autocomplete.length" class="autocomplete-list">
                    <li v-for="item in location_autocomplete" @click="select_location(item)">{{ item }}</li>
                </ul>
            </div>

            <div>
                <input v-model="name" type="text" name="name" :placeholder="$gettext('organization name')">
                <ul v-if="name && name_autocomplete.length" class="autocomplete-list">
                    <li v-for="item in name_autocomplete" @click="select_name(item)">{{ item }}</li>
                </ul>
            </div>
            <button class="btn btn--primary btn--large" v-if="button === 'cta'" v-translate>search</button>
            <button v-else><img src="/static/img/svg/icn-search.svg"></button>
        </div>
    </form>
</template>

<script>
import _ from 'lodash'
import axios from 'axios'

function input_bounce(callback) {
    return _.debounce(callback, 700, {'leading': true})
}


export default {
    name: "MemberSearch",
    props: ['initial', 'url', 'button'],
    data() {
        return {
            location: this.initial.location,
            location_autocomplete: [],

            name: this.initial.name,
            name_autocomplete: [],
            
            backend_url: this.url || './'
        }
    },
    methods: {
        select_location(value) {
            this.location = value
            this.$nextTick(() => this.$el.submit())
        },
        select_name(value) {
            this.name = value
            this.$nextTick(() => this.$el.submit())
        },
        autocomplete_locations: input_bounce(function (e) {
            axios.get(this.backend_url, {params: {autocomplete: 1, location: this.location}}).then(r => {
                this.location_autocomplete = r.data
            })
        }),
        autocomplete_names: input_bounce(function (e) {
            axios.get(this.backend_url, {params: {autocomplete: 1, name: this.name}}).then(r => {
                this.name_autocomplete = r.data
            })
        }),
    },
    watch: {
        location() {
            this.autocomplete_locations()
        },
        name() {
            this.autocomplete_names()
        },
    },
}
</script>

<style>
    .autocomplete-list {
        position: absolute;
        width: 300px;
        background-color: white;
        border: 1px solid #ccc;
        z-index: 100;
        padding: 6px;
        margin-top: 1px;
        box-shadow: 2px 2px 10px #ccc;
    }
    .autocomplete-list li {
        padding: 2px 4px;
    }
    .autocomplete-list li:hover {
        background-color: #eee;
    }
</style>