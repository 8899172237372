<template>

  <form class="claimform wizard">

    <div v-if="has_debug"
         style="height: 400px; overflow: scroll; width: 300px; position: fixed; top: 10px; right: 10px; background-color: white; z-index: 10000">
      <button @click.prevent="debug_fill_required">fill...</button>
      <pre style="font-size: 0.8rem;">{{ values }}</pre>
    </div>

    <div v-if="finished">
      <h3 v-translate>Saved.</h3>
      <p v-translate>Thank you for your submission.</p>
    </div>

    <div v-if="!finished">

      <h2 v-translate>Become a member form selfemployed</h2>


      <Step :title="$gettext('01. General Information')"
            :validated="step1_valid"
            :open="current_step == 1"
            @stepNext="on_next_step"
            @stepOpen="current_step = 1">
        <div class="row" v-for="(row, index) in step1_fields" :key="index">

          <Field v-for="field in row" :key="field.name"
                 :field="field"
                 :form="form"
                 :class="classes[field.name]"
                 v-model="values[field.name]">
          </Field>
        </div>
      </Step>

      <Step :title="$gettext('02. Official address')"
            :validated="step2_valid"
            :open="current_step == 2"
            @stepNext="on_next_step"
            @stepOpen="current_step = 2">
        <div class="row" v-for="(row, index) in step2_fields" :key="index">

          <Field v-for="field in row" :key="field.name"
                 :field="field"
                 :form="form"
                 :class="classes[field.name]"
                 v-model="values[field.name]">
          </Field>
        </div>
      </Step>


      <Step :title="$gettext('03. Trading address')"
            :validated="step3_valid"
            :open="current_step == 3"
            @stepNext="on_next_step"
            @stepOpen="current_step = 3">
        <div class="row" v-for="(row, index) in step3_fields" :key="index">

          <Field v-for="field in row" :key="field.name"
                 :field="field"
                 :form="form"
                 :class="classes[field.name]"
                 v-model="values[field.name]">
          </Field>
        </div>
      </Step>

      <Step :title="$gettext('04. State of wealth (assets)')"
            :validated="step4_valid"
            :open="current_step == 4"
            @stepNext="on_next_step"
            @stepOpen="current_step = 4">
        <div class="row" v-for="(row, index) in step4_fields" :key="index">

          <Field v-for="field in row" :key="field.name"
                 :field="field"
                 :form="form"
                 :class="classes[field.name]"
                 v-model="values[field.name]">
          </Field>
        </div>
      </Step>

      <Step :title="$gettext('05. State of wealth (debts)')"
            :validated="step5_valid"
            :open="current_step == 5"
            @stepNext="on_next_step"
            @stepOpen="current_step = 5">
        <div class="row" v-for="(row, index) in step5_fields" :key="index">
          <Field v-for="field in row" :key="field.name"
                 :field="field"
                 :form="form"
                 :class="classes[field.name]"
                 v-model="values[field.name]">
          </Field>
        </div>
      </Step>

      <Step :title="$gettext('06. Details of the external (certified) accountant or auditor')"
            :validated="step6_valid"
            :open="current_step == 6"
            @stepNext="on_next_step"
            @stepOpen="current_step = 6">
        <div class="row" v-for="(row, index) in step6_fields" :key="index">
          <Field v-for="field in row" :key="field.name"
                 :field="field"
                 :form="form"
                 :class="classes[field.name]"
                 v-model="values[field.name]">
          </Field>
        </div>
      </Step>

      <Step :title="$gettext('07. Structure and activity')"
            :validated="step7_valid"
            :open="current_step == 7"
            @stepNext="on_next_step"
            @stepOpen="current_step = 7">

        <div class="row">
          <strong v-translate>Activity (ies) of your travel company</strong>
        </div>

        <div class="row" v-for="(row, index) in step7_fields" :key="index">

          <Field v-for="field in row" :key="field.name"
                 :field="field"
                 :form="form"
                 :class="classes[field.name]"
                 v-model="values[field.name]">
          </Field>
        </div>
      </Step>

      <Step :title="$gettext('08. Upload documents')"
            :validated="step8_valid"
            :open="current_step == 8"
            @stepNext="on_next_step"
            @stepOpen="current_step = 8">
        <div class="row" v-for="(row, index) in step8_fields" :key="index">

          <Field v-for="field in row" :key="field.name"
                 :field="field"
                 :form="form"
                 :class="classes[field.name]"
                 v-model="values[field.name]">
          </Field>
        </div>
      </Step>

      <Step :title="$gettext('09. Signature')"
            :validated="step9_valid"
            :open="current_step == 9"
            :hasNext="false"
            @stepOpen="current_step = 9">
        <div class="row" v-for="(row, index) in step9_fields" :key="index">
          <Field v-for="field in row" :key="field.name"
                 :field="field"
                 :form="form"
                 v-model="values[field.name]">
          </Field>
        </div>
        <div class="row" v-if="!values.confirm_send1 && !values.confirm_send2">
          <div style="color: red" v-translate>Please check both to proceed</div>
        </div>
        <div class="row">
          <div class="form-field">
            <label for="">Signature<span>*</span></label>
            <div class="field">
              <div class="signature__result" @click="signature_popup = true">
                <img :src="values.signature" v-if="values.signature">
                <div v-else class="help" v-translate>Please click for your signature</div>
              </div>
            </div>
          </div>
        </div>
      </Step>

      <div class="row">
        <button class="btn"
                :class="{'btn--disabled': !can_submit, 'btn--primary': can_submit}"
                @click.prevent="submit_form()"
                v-translate>
          Submit request
        </button>
      </div>

      <div v-if="signature_popup">
        <popup>
          <Signature v-model="values.signature"
                     @finish="signature_popup = false"/>
        </popup>
      </div>
      <span class="translation-helper" style="display:none;" v-translate>
          * Travel organization = combination of min. two travel services
        </span>

    </div>
  </form>
</template>

<script>
import _ from 'lodash'
import axios from '../../api'
import Step from '../claimform/Step.vue'
import Field from '../forms/Field.vue'
import Signature from '../Signature.vue'
import {WizardMixin} from '../../mixins/wizaard_forms.js'
import {
  addValidators,
  required,
  minLength,
  email,
  sameAs,
  between,
  requiredIf,
  atLeastOneRequired
} from "../forms/validation.js"
import popup from '@/components/popup';


export default {
  name: 'B2BMembershipForm',
  mixins: [WizardMixin],
  props: [
    // + WizardMixin: initialForm
  ],
  components: {
    Step,
    Field,
    Signature,
    popup
  },
  data() {
    return {
      // + WizardMixin: current_step, form, values, has_debug
      finished: false,
      classes: {}, // css
      signature_popup: false,
    }
  },
  mounted() {
    addValidators(this.form, this.values, {
      trade_email: [required, email],
      trade_email_repeat: [required, email, sameAs('trade_email')],
      real_estate_value: [between(0, 999999)],
      movable_property: [between(0, 999999)],
      real_estate_KI: [between(0, 999999)],
      other_income: [between(0, 999999)],
      company_group_kind: [requiredIf('company_group')],
      company_group_pct: [requiredIf('company_group')],

      mortgage_total: [between(0, 999999)],
      mortgage_due: [between(0, 999999)],
      liabilities_total: [between(0, 999999)],
      liabilities_due: [between(0, 999999)],
      other_charges: [between(0, 999999)],

      how_many_1: [between(0, 99999999)],
      how_many_2: [between(0, 99999999)],
      how_many_3: [between(0, 999999)],
      how_many_4: [between(0, 999999)],
      how_many_5: [between(0, 999999)],
      num_branches: [between(0, 999999)],
      num_franchisees: [between(0, 999999)],
      associations: [between(0, 999999)],

      to_sporadic_activity: [atLeastOneRequired(['reseller_belgian_foreign', 'reseller_belgian', 'retailer_foreign', 'to_main_activity', 'to_secondary_activity', 'to_sporadic_activity'])]
    })
    const translationHelper = this.$el.querySelector('.translation-helper');
    if (translationHelper) {
      const content = translationHelper.textContent.trim();
      const field = this.$el.querySelector('.field-reseller_singular');
      if (field) {
        field.setAttribute('data-content', content);
      }
    }
  },
  computed: {
    // + WizardMixin: formFields

    step1_valid() {
      return this.check_required_fields(this.step1_fields)
    },
    step2_valid() {
      return this.step1_valid && this.check_required_fields(this.step2_fields)
    },
    step3_valid() {
      return this.step2_valid && this.check_required_fields(this.step3_fields)
    },
    step4_valid() {
      return this.step3_valid && this.check_required_fields(this.step4_fields)
    },
    step5_valid() {
      return this.step4_valid && this.check_required_fields(this.step5_fields)
    },
    step6_valid() {
      return this.step5_valid && this.check_required_fields(this.step6_fields)
    },
    step7_valid() {
      return this.step6_valid && this.check_required_fields(this.step7_fields) && this.max_step >= 7
    },
    step8_valid() {
      return this.step7_valid && this.check_required_fields(this.step8_fields) && this.max_step >= 8
    },
    step9_valid() {
      return this.step8_valid && this.check_required_fields(this.step9_fields) && Boolean(this.values.signature)
    },

    can_submit() {
      return this.step8_valid
    },

    // fields --------------------------


    step1_fields() {
      let rows = [
        ['title'],
        ['firstname', 'lastname'],
        ['language', 'birthdate'],
        ['civil_status'],
      ]
      return this.row_fields(rows)
    },

    step2_fields() {
      let rows = [
        ['street', 'number', 'box'],
        ['postalcode', 'city', 'country'],
      ]
      return this.row_fields(rows)
    },


    step3_fields() {
      let rows = [
        ['name'],
        ['trade_street', 'trade_number', 'trade_box'],
        ['trade_postalcode', 'trade_city', 'trade_country'],

        ['trade_phone', 'trade_mobile'],
        ['trade_email', 'trade_email_repeat'],
        ['trade_website'],
        ['company_number', 'iata_number'],
        ['iban'],
      ]
      return this.row_fields(rows)
    },

    step4_fields() {
      let rows = [
        ['real_estate_value', 'real_estate_KI'],
        ['owner'],
        ['seizure_home'],
        ['mortgage_or_attorney'],
        ['business_pledge'],
        ['movable_property', 'other_income'],
      ]
      if (this.values.owner !== true) {
        _.remove(rows, el => el == 'seizure_home')
        _.remove(rows, el => el == 'mortgage_or_attorney')
        _.remove(rows, el => el == 'business_pledge')
      }
      return this.row_fields(rows)
    },

    step5_fields() {
      let rows = [
        ['mortgage_total'],
        ['mortgage_due'],
        ['liabilities_total'],
        ['liabilities_due'],
        ['other_charges'],
      ]
      return this.row_fields(rows)
    },

    step6_fields() {
      return this.row_fields([
        ['accountant_firstname', 'accountant_lastname'],
        ['accountant_company', 'accountant_itaa'],
        ['accountant_street', 'accountant_number'],
        ['accountant_postalcode'],
        ['accountant_city'],
        ['accountant_country'],
        ['accountant_tel', 'accountant_mobile'],
        ['accountant_email'],
        ['accountant_website'],
      ])
    },


    step7_fields() {
      let rows = [
        ['to_main_activity'],
        ['to_secondary_activity'],
        ['to_sporadic_activity'],
        ['reseller_belgian'],
        ['retailer_foreign'],
        ['reseller_singular'],
        // ['reseller_belgian_foreign'],
        // ----
        ['company_group'],
        ['company_group_kind'],
        ['company_group_pct'],
        // --
        ['guarantee'],
        ['guarantee_org'],
        ['how_many_1'],
        ['how_many_2'],
        ['how_many_3'],
        ['how_many_4'],
        ['how_many_5'],
        ['num_branches'],
        ['num_franchisees'],
        ['associations'],
        ['outside_deadlines'],
        ['outside_deadlines_terms'],
        ['outside_deadlines_file'],
      ]
      if (this.values.company_group !== true) {
        _.remove(rows, el => el == 'company_group_kind')
        _.remove(rows, el => el == 'company_group_pct')
      }
      if (this.values.guarantee !== true) {
        _.remove(rows, el => el == 'guarantee_org')
      }
      if (this.values.outside_deadlines !== true) {
        _.remove(rows, el => el == 'outside_deadlines_terms')
        _.remove(rows, el => el == 'outside_deadlines_file')
      }
      return this.row_fields(rows)
    },

    step8_fields() {
      let rows = [
        ['personal_income'],
        ['businessplan'],
        ['insurance_cert'],
        ['expected_revenue'],
        ['brochure'],
      ]
      return this.row_fields(rows)
    },

    step9_fields() {
      let rows = [
        ['place'],
        ['date'],
        ['confirm_send1'],
        ['confirm_send2'],
      ]
      return this.row_fields(rows)
    },

    current_step_fields() {
      const attr = this['step' + this.current_step + '_fields']
      let fields = []
      attr.forEach(row => row.forEach(f => fields.push(f)))
      return fields
    },
    current_step_valid() {
      for (const f of this.current_step_fields) {
        if (this.form.errors[f.name]) {
          return false
        }
      }
      return true
    }

  },
  methods: {
    // + WizardMixin:
    //      - row_fields(rows)
    //      - check_required_fields(rows)

    on_next_step() {
      this.force_fields_validate(this.current_step_fields)
      this.$nextTick(() => {
        if (this.current_step_valid) {
          this.current_step += 1
          this.scrollToStepStart()
        }
      })
    },
    submit_form(validate) {
      if (!this.can_submit) {
        alert(this.$gettext('Please fill in all the required fiels.'))
        return
      }
      let data = this.get_form_data()

      let url = './'
      if (validate) {
        url += '?validate=1'
      }

      axios.post(url, data)
          .then(response => {
            if (!response.data.valid) {
              this.form = response.data.form
              this.find_server_error_step()
            } else {
              this.finished = true
            }
          })
    },

  },

}
</script>

<style scoped>
.field-reseller_singular {
  margin-bottom: 30px;
  position: relative;
}

.field-reseller_singular::after {
  content: attr(data-content);
  font-size: 12px;
  position: absolute;
  top: 100%;
}
</style>