<template>
  <div class="step" :class="{'step--open': open}">
    <h3 class="step__title" :class="{'validated': validated}">
      <span class="title">{{ title }}</span>
      <span class="change" @click="$emit('stepOpen')" v-if="validated" v-translate>change</span></h3>
    <div class="step__body">
      <slot></slot>
      
      <div class="btn-row btn-row--right" v-if="hasNext">
        <button class="btn btn--secondary" @click.prevent="on_next" v-translate>
          next step
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Step',
  props: {
    title: String,
    open: {
      type: Boolean,
      default: false
    },
    validated: {
      type: Boolean,
      default: false,
    },
    hasNext: {
      type: Boolean,
      default: true
    },
  },
  methods: {
    on_next() {
      // if (this.validated) {
      //   this.$emit('stepNext')
      // }
      this.$emit('stepNext')
    }
  }
}
</script>

<style>
.disabled{
  background-color: #ccc;
}
</style>