<template>
  <div class="success">
    <h2 v-translate>Online claim form</h2>
    <div class="success__msg">
      <div class="success__content" v-html="success_msg"></div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['success_msg']
}
</script>