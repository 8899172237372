<template>
  <div class="signature">
    <VueSignature class="signature--sign" ref="signature" :sigOption="options" :w="'100%'" :h="'100%'"></VueSignature>
    <div class="row">
<!--      <button @click.prevent="clear" class="btn btn&#45;&#45;outline">Clear</button>&nbsp;-->
      <button @click.prevent="cancel" class="btn btn--outline" v-translate>Cancel</button>
      <button @click.prevent="save" class="btn btn--primary" v-translate>Confirm</button>
    </div>
  </div>
</template>

<script>

import VueSignature from "vue-signature"

export default {
  name: 'Signature',
  props: [
    'value',
  ],
  data() {
    return {
      widget_value: this.value,
      options: {}, //onEnd: this.onFinishSign
    }
  },
  methods: {
    // onFinishSign() {
    //     var svg = this.$refs.signature.save('image/svg+xml')
    //     //console.info(svg)
    //     this.$emit('input', svg)
    // },
    save() {
      var svg = this.$refs.signature.save('image/svg+xml')
      this.$emit('input', svg)
      this.$emit('finish')
    },
    cancel() {
      this.$refs.signature.clear()
      this.$emit('finish')
    },
    clear() {
      this.$refs.signature.clear()
      this.$emit('input', '')
    }
  },
  components: {
    VueSignature
  },
}
</script>
