<template>

  <form class="claimform wizard">

    <div v-if="has_debug"
         style="height: 400px; overflow: scroll; width: 300px; position: fixed; top: 10px; right: 10px; background-color: white; z-index: 10000">
      <button @click.prevent="debug_fill_required">fill...</button>
      <pre style="font-size: 0.7rem;">{{ values }}</pre>
    </div>

    <success_app v-if="finished" :success_msg="success_msg"/>
    <div v-if="!finished">

      <h2 v-translate>Online claim form</h2>

      <Step :title="$gettext('01. Who has been declared insolvent?')"
            :validated="step1_valid"
            :open="current_step == 1"
            @stepNext="on_next_step"
            @stepOpen="current_step = 1">

        <div class="row">
          <div class="form-field">
            <label><span v-translate style="color:#000">Name of your travel operator, agent or agency</span><span>*</span></label>
            <select required v-model="operator_select" @change="on_operator_select_change">
              <option disabled v-translate>--- Please select a travel operator, agent or agency ---</option>
              <option v-for="org in organizations" :value="org.title">{{ org.title }}</option>
              <option value="_other_" v-translate>Other...</option>
            </select>

            <div v-if="operator_select == '_other_'" style="margin-top: 10px;">
              <input type="text" v-model="values.bankruptcy_name" :placeholder="$gettext('Type other...')">
            </div>

            <div v-if="form.errors['bankruptcy_name']" class="field-error" style="color: red;">
              {{ form.errors['bankruptcy_name'] }}
            </div>
          </div>
        </div>


        <div class="row">
          <label class="flex">
            <input type="radio" name="where_am_i" :value="true" v-model="values.is_before">
            <div>
              <strong v-translate>I'm already at my destination</strong>
            </div>
          </label>
        </div>

        <div class="row">
          <label class="flex">
            <input type="radio" name="where_am_i" :value="false" v-model="values.is_before">
            <div>
              <strong v-translate>I have to depart for holiday in the future</strong>
            </div>
          </label>
        </div>

        <div v-if="form.errors['is_before']" class="field-error" style="color: red;">{{
            form.errors['is_before']
          }}
        </div>
      </Step>

      <Step :title="$gettext('02. Documents')"
            :validated="step2_valid"
            :open="current_step == 2"
            @stepNext="step2_valid && on_next_step()"
            @stepOpen="current_step = 2">
        <div class="row row--content">
          <div>
            <strong v-translate>Please provide the following documents.</strong>
            <ul class="">
              <li v-translate>the booking form/invoice from your trip</li>
              <li v-translate>the proof of payment</li>
              <li><span v-translate>if applicable, the travel documents you have already received:</span>
                <span v-translate>for example tickets vouchers ...</span></li>


            </ul>
          </div>
        </div>
        <InlineUploadField v-model="fileList"/>

        <div class="row" v-if="!step2_valid">
          <div style="color: red;" v-translate>Document(s) required</div>
        </div>

      </Step>

      <Step :title="$gettext('03. My details')"
            :validated="step3_valid"
            :open="current_step == 3"
            @stepNext="on_next_step"
            @stepOpen="current_step = 3">
        <div class="row" v-for="(row, index) in step3_fields" :key="index">
          <Field v-for="field in row" :key="field.name"
                 :field="field"
                 :form="form"
                 :class="classes[field.name]"
                 v-model="values[field.name]">
          </Field>
        </div>
      </Step>

      <Step :title="$gettext('04. Trip details')"
            :validated="step4_valid"
            :open="current_step == 4"
            @stepNext="on_next_step"
            @stepOpen="current_step = 4">
        <div class="row" v-for="(row, index) in step4_fields" :key="index">
          <Field v-for="field in row" :key="field.name"
                 :field="field"
                 :class="classes[field.name]"
                 :form="form"
                 v-model="values[field.name]">
          </Field>
        </div>
        <div class="row">
          <checkbox-field v-model="values['voucher']" :label="formFields['voucher']['label']" />
<!--          <label class="checkbox"><input type="checkbox" v-model="values['voucher']">{{-->
<!--              formFields['voucher']['label']-->
<!--            }} </label>-->
        </div>
        <h4 class="line-top" v-translate>Other info</h4>
        <div class="row">
          <Field :field="formFields['airline']"
                 :form="form"
                 v-model="values['airline']"/>
        </div>

        <div class="row">
          <InlineField v-model="hotels" :label="$gettext('Accomodation (Hotel, appartment, camping)')" :maxItems="3"/>
          
        </div>
        <div class="row" v-if="values.is_before && !hotels[0]">
          <div style="color: red; margin-top: -20px;" v-translate>This field is required</div>
        </div>

        <div class="row">
          <InlineField v-model="suppliers" :label="$gettext('Services (rental car, transfers...)')" :maxItems="3"/>
        </div>
        <div class="row" v-if="values.is_before && !suppliers[0]">
          <div style="color: red; margin-top: -20px;" v-translate>This field is required</div>
        </div>
        
        <div class="row">
          <Field :field="formFields['comments']"
                 :form="form"
                 v-model="values['comments']"/>
        </div>
      </Step>

      <Step :title="$gettext('05. Signature')"
            :validated="step5_valid"
            :open="current_step == 5"
            :hasNext="false"
            @stepOpen="current_step = 5">
        <div class="row" v-for="(row, index) in step5_fields" :key="index">
          <Field v-for="field in row" :key="field.name"
                 :field="field"
                 :form="form"
                 v-model="values[field.name]">
          </Field>
        </div>
        <div class="row" v-if="!values.confirm_send">
          <div style="color: red" v-translate>This field is required</div>
        </div>
        <div class="row">
          <div class="form-field">
            <label for="" v-translate><span v-translate style="color:#000">Signature</span><span>*</span></label>
            <div class="field">
              <div class="signature__result" @click="signature_popup = true">
                <img :src="values.signature" v-if="values.signature">
                <div v-else class="help" v-translate>Please click for your signature</div>
              </div>
            </div>
          </div>
        </div>
      </Step>

      <div class="row">
        <button class="btn"
                :class="{'btn--disabled': !can_submit, 'btn--primary': can_submit}"
                @click.prevent="submit_form()" v-translate>
          Submit claim
        </button>
      </div>

      <div v-if="signature_popup">
        <popup>
          <Signature v-model="values.signature"
                     @finish="signature_popup = false"/>
        </popup>
      </div>
    </div>
  </form>
</template>

<script>
import _ from 'lodash'
import axios from '../../api'
import Step from './Step.vue'
import Field from '../forms/Field.vue'
import Signature from '../Signature.vue'
import InlineUploadField from '../forms/InlineUploadField'
import InlineField from "@/components/forms/InlineField";
import {WizardMixin} from '../../mixins/wizaard_forms.js'
import {addValidators, required, minLength, email, sameAs, date, between, requiredIf} from "../forms/validation.js"
import moment from 'moment-mini'
import popup from '@/components/popup';
import success_app from "@/components/success"
import CheckboxField from "@/components/forms/CheckboxField";


export default {
  name: 'ClaimForm',
  mixins: [WizardMixin],
  props: [
    // + WizardMixin: initialForm
    'organizations',
    'success_msg'
  ],
  components: {
    CheckboxField,
    Step,
    Field,
    Signature,
    InlineUploadField,
    InlineField,
    popup,
    success_app
  },
  data() {
    return {
      // + WizardMixin: current_step, form, values, has_debug
      finished: false,

      operator_select: null, // for step1

      classes: {},
      signature_popup: false,

      fileList: [null],
      hotels: [],
      suppliers: []
    }
  },
  mounted() {
    addValidators(this.form, this.values, {
      bankruptcy_name: [required],
      is_before: [required],
      first_name: [required, minLength(2)],
      last_name: [required, minLength(2)],
      email: [required, email],
      repeat_email: [required, email, sameAs('email')],
      claimed_amount: [required, between(1, 100000)],

      booking_date: [required, date, this.validate_trip_dates],
      departure_date: [required, date, this.validate_trip_dates],
      return_date: [required, date, this.validate_trip_dates],
      airline: [requiredIf('is_before')],

      // b2b
      b2b_organization: [requiredIf('b2b')],
      b2b_identity: [requiredIf('b2b')],
      b2b_address: [requiredIf('b2b')],
      b2b_phone: [requiredIf('b2b')],
      b2b_email: [requiredIf('b2b'), email],
    })
  },
  computed: {
    // + WizardMixin: formFields

    step1_valid() {
      let result = Boolean(this.values.bankruptcy_name) && (this.values.is_before !== null)
      return result
    },
    step2_valid() {
      if (this.values.is_before === false) {
        if (_.filter(this.fileList, f => Boolean(f)).length == 0) {
          return false
        }
      }
      return this.current_step >= 2 || this.step3_valid
    },
    step3_valid() {
      return this.check_required_fields(this.step3_fields) && this.values.repeat_email == this.values.email
    },
    step4_valid() {
      if (this.values.is_before) {
        if(!this.values.airline || !this.hotels[0] || !this.suppliers[0]) {
          return false;
        }
      }
      return this.check_required_fields(this.step4_fields)
    },
    step5_valid() {
      return this.check_required_fields(this.step5_fields) && Boolean(this.values.signature)
    },
    can_submit() {
      return this.step5_valid
    },

    // fields --------------------------

    step1_fields() {
      return this.row_fields([
        ['is_before', 'bankruptcy_name'],
      ])
    },

    step2_fields() {
      return [] // file uploads are not part of form.values
    },

    step3_fields() {
      let rows = [
        // b2b
        ['b2b'],
        ['b2b_organization'],
        ['b2b_identity'],
        ['b2b_address'],
        ['b2b_phone', 'b2b_email'],
        // person details
        ['title'],
        ['first_name', 'last_name'],
        ['language'],
        ['street', 'number', 'box'],
        ['post_code', 'city', 'country'],
        ['phone', 'mobile'],
        ['email', 'repeat_email'],
      ]
      if (this.values.b2b !== true) {
        //hide-show b2b fields
        _.remove(rows, el => el[0].indexOf('b2b_') != -1)
        this.values.company_group_pct = null
      }
      return this.row_fields(rows)
    },
    step4_fields() {
      return this.row_fields([
        ['destination'],
        ['booking_date', 'departure_date', 'return_date'],
        ['pax'],
        // ['airline'],
        ['po_number', 'case_number'],
        ['claimed_amount'],
        // ['voucher'],
        // ['comments'],
      ])
    },
    step5_fields() {
      return this.row_fields([
        ['signature_date'],
        ['signature_place'],
        ['confirm_send'],
      ])
    },


    current_step_fields() {
      const attr = this['step' + this.current_step + '_fields']
      let fields = []
      attr.forEach(row => row.forEach(f => fields.push(f)))
      return fields
    },
    current_step_valid() {
      return this['step' + this.current_step + '_valid']
      // for (const f of this.current_step_fields) {
      //   if (this.form.errors[f.name]) {
      //     return false
      //   }
      // }
      // return true
    }
  },
  methods: {
    // + WizardMixin: 
    //      - row_fields(rows)
    //      - check_required_fields(rows)

    on_next_step() {
      console.info(['on_next_step', this.current_step_valid])
      this.force_fields_validate(this.current_step_fields)
      if (this.current_step == 4) {
        // bacause airline in step4 and live in different section - has to manually trigger this:
        this.force_fields_validate([this.formFields['airline']]) 
      }
      this.$nextTick(() => {
        if (this.current_step_valid) {
          this.current_step += 1
          this.scrollToStepStart()
        }
      })
    },
    on_operator_select_change() {
      if (!this.operator_select || this.operator_select == '_other_') {
        this.values.bankruptcy_name = null
      } else {
        this.values.bankruptcy_name = this.operator_select
      }
    },

    validate_trip_dates(value, field, form_values) {
      let d1 = moment(form_values.booking_date, "DD/MM/YYYY")
      let d2 = moment(form_values.departure_date, "DD/MM/YYYY")
      let d3 = moment(form_values.return_date, "DD/MM/YYYY")
      if (d1.toDate() > d2.toDate() || d2.toDate() > d3.toDate()) {
        return this.$gettext('Please input dates correct sequence')
      }
    },


    submit_form(validate) {
      if (!this.can_submit) {
        alert(this.$gettext('Please fill in all the required fiels.'))
        return
      }
      let data = new FormData();
      for (const key in this.values) {
        if (this.values[key] !== null) {
          data.append(key, this.values[key])
        }
      }

      for (const h of this.hotels) {
        data.append('hotels', h)
      }

      for (const s of this.suppliers) {
        data.append('suppliers', s)
      }

      let url = './'
      if (validate) {
        url += '?validate=1'
      }

      for (const file of this.fileList) {
        if (file) {
          data.append('files', file)
        }
      }

      axios.post(url, data)
          .then(response => {
            if (!response.data.valid) {
              this.form = response.data.form
              this.find_server_error_step()
            } else {
              this.finished = true
            }
          })
    },
  },

}
</script>

<style scoped>
.signature__result {
  background: #fff;
  margin-bottom: 1rem;
}

.signature__result img {
  width: 50%;
}

.signature__button {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.help {
  font-size: 1.2rem;
}

.field-title {
  margin-top: 30px;
}
</style>