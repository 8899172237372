import Vue from 'vue';
import moment from 'moment-mini'

export const ValidateEvents = new Vue({
    methods: {
        validate(name) {
            this.$emit('validate', name)
        }
    }
});


export function addValidators(form, form_values, validators) {
    let form_validators = {}

    form.fields.forEach(f => {
        form_validators[f.name] = []
        let auto_validators = [] // automatically detected from django
        if (f.required) {
            form_validators[f.name].push(required)
        }
        if (f.input_type.is_date) {
            form_validators[f.name].push(date)
        }
    })

    for (const [name, callbacks] of Object.entries(validators)) {
        form_validators[name] = callbacks
    }

    ValidateEvents.$on('validate', ({value, field}) => {
        const field_validators = form_validators[field.name]
        if (field_validators === undefined) {
            return
        }
    
        let messages = []
    
        field_validators.forEach(callback => {
            let msg = callback(value, field, form_values)
            if (msg) {
                messages.push(msg) 
            }
        })
    
        if (messages.length) {
            Vue.set(form.errors, field.name, messages[0])
        } else {
            Vue.set(form.errors, field.name, null)
        }
    })
    

}




// -------------- validators -----------------------


function req(value) {
    if (Array.isArray(value)) return !!value.length
    if (value === undefined || value === null) {
      return false
    }
  
    if (value === false) {
      return true
    }
  
    if (value instanceof Date) {
      // invalid date won't pass
      return !isNaN(value.getTime())
    }
  
    if (typeof value === 'object') {
      for (let _ in value) return true
      return false
    }
  
    return !!String(value).length
  }


export function required(value, field, form_values) {
    if (req(value)) {
        return
    }
    return ValidateEvents.$gettext("This field is required")
}


export function minLength(length) {
    return function(value, field, form_values) {
        if (value && value.length < length)
            return ValidateEvents.$gettextInterpolate("Value must be at least %{ length } characters", {length})
    }
}

export function email(value, field, form_values) {
    if (value && !emailRegex.test(value)) {
        return ValidateEvents.$gettext("Email address is not valid")
    }
}

export function sameAs(other_name) {
    return function(value, field, form_values) {
        if (value && value != form_values[other_name]) {
            return ValidateEvents.$gettext("Values are not the same")
        }
    }
}

export function requiredIf(other_name) {
    return function(value, field, form_values) {
        if (form_values[other_name] && !value) {
            return ValidateEvents.$gettext("This field is required")
        }
    }
}

export function between(min, max) {
    return function(value, field, form_values) {
        if (req(value) && (+value < min || +value > max)) {
            return ValidateEvents.$gettext("Value is not valid")
        }
    }
}

export function date(value, form, form_values) {
    const err_msg = ValidateEvents.$gettext('Invalid date (DD/MM/YYYY)')
    if (value && value.length < 10) {
        return err_msg
    }
    let d = moment(value, "DD/MM/YYYY")
    if (!d.isValid()) {
        return err_msg
    }
    if (d.toDate().getFullYear() < 1900) {
        return err_msg
    }
}

export function atLeastOneRequired(names) {
    return function(value, field, form_values) {
        let num_set = names.filter(name => {
            return !!form_values[name]
        })
        if (num_set.length == 0) {
            return ValidateEvents.$gettext("At least one option required")
        }
    }
}


// This regex must be last line - because it seems breaking extract-translations script
const emailRegex = /^(?:[A-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[A-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9]{2,}(?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/i
