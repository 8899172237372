<template>
  <div class="inline">
    <div class="row-line" v-for="(item, key) in values" :key="key">
      <label>{{ label }}</label>
      <div class="field-row">
        <input @change="$emit('input', values)" v-model="values[key]">
        <a v-if="key > 0" @click="deleteLine(key)" class="deleteLine" v-translate>delete</a>
      </div>
    </div>
    <a @click="addLine" class="addmore" :class="{'addmore--disabled': !canAddLine}" v-translate>+ add more</a>
  </div>
</template>

<script>
import Field from './Field'

export default {
  name: "InlineField",
  components: {
    Field,
  },
  props: {
    label: String,
    maxItems: {
      type: Number,
      default: 10
    }
  },
  data() {
    return {
      values: [null],
    }
  },
  computed: {
    canAddLine() {
      if (this.values.length >= this.maxItems) {
        return false
      }
      if (this.values[this.values.length - 1]) {
        return true
      }
      return false
    }
  },
  methods: {
    addLine() {
      if (this.canAddLine) {
        this.values.push(null)
      }
    },
    deleteLine(i) {
      this.values.splice(i, 1)
    }
  }
}
</script>
<style scoped>
.row-line {
  margin-bottom: 0.5rem;
}

.field-row {
  display: flex;
  align-items: center;
}

.field-row a {
  padding: 0.5rem 0 0.5rem 1rem;
}
.addmore--disabled {
  display: none;
}

</style>