<template>

  <form class="claimform wizard">

    <div v-if="has_debug"
         style="height: 400px; overflow: scroll; width: 300px; position: fixed; top: 10px; right: 10px; background-color: white; z-index: 10000">
      <button @click.prevent="debug_fill_required">fill...</button>
      <pre style="font-size: 0.7rem;">{{ values }}</pre>
    </div>

    <div v-if="finished">
      <h3 v-translate>Saved.</h3>
      <p v-translate>Thank you for your submission.</p>
    </div>

    <div v-if="!finished">

      <h2 v-translate>Become a member form companies</h2>


      <Step :title="$gettext('01. General Information')"
            :validated="step1_valid"
            :open="current_step == 1"
            @stepNext="on_next_step"
            @stepOpen="current_step = 1">
        <div class="row" v-for="(row, index) in step1_fields" :key="index">

          <Field v-for="field in row" :key="field.name"
                 :field="field"
                 :form="form"
                 :class="classes[field.name]"
                 v-model="values[field.name]">
          </Field>
        </div>
      </Step>

      <Step :title="$gettext('02. Address')"
            :validated="step2_valid"
            :open="current_step == 2"
            @stepNext="on_next_step"
            @stepOpen="current_step = 2">
        <div class="row" v-for="(row, index) in step2_fields" :key="index">
          <Field v-for="field in row" :key="field.name"
                 :field="field"
                 :form="form"
                 :class="classes[field.name]"
                 v-model="values[field.name]">
          </Field>
        </div>
        <div class="row">
          <h3 v-translate>Commercial address (if different)</h3>
        </div>
        <div class="row" v-for="(row, index) in step2_commercial_fields" :key="'comerc_' + index">

          <Field v-for="field in row" :key="field.name"
                 :field="field"
                 :form="form"
                 :class="classes[field.name]"
                 v-model="values[field.name]">
          </Field>
        </div>
      </Step>


      <Step :title="$gettext('03. Trade data')"
            :validated="step3_valid"
            :open="current_step == 3"
            @stepNext="on_next_step"
            @stepOpen="current_step = 3">
        <div class="row" v-for="(row, index) in step3_fields" :key="index">

          <Field v-for="field in row" :key="field.name"
                 :field="field"
                 :form="form"
                 :class="classes[field.name]"
                 v-model="values[field.name]">
          </Field>
        </div>
      </Step>

      <Step :title="$gettext('04. Contact person in charge of the daily management')"
            :validated="step4_valid"
            :open="current_step == 4"
            @stepNext="on_next_step"
            @stepOpen="current_step = 4">
        <div class="row" v-for="(row, index) in step4_fields" :key="index">

          <Field v-for="field in row" :key="field.name"
                 :field="field"
                 :form="form"
                 :class="classes[field.name]"
                 v-model="values[field.name]">
          </Field>
        </div>

        <div class="row">
          <h3 v-translate>Business manager / managing director (if different from contact person above)</h3>
        </div>

        <div class="row" v-for="(row, index) in step4_manager_fields" :key="'comerc_' + index">

          <Field v-for="field in row" :key="field.name"
                 :field="field"
                 :form="form"
                 :class="classes[field.name]"
                 v-model="values[field.name]">
          </Field>
        </div>
      </Step>
      <Step :title="$gettext('05. Details of the external (certified) accountant or auditor')"
            :validated="step5_valid"
            :open="current_step == 5"
            @stepNext="on_next_step"
            @stepOpen="current_step = 5">
        <div class="row" v-for="(row, index) in step5_fields" :key="index">
          <Field v-for="field in row" :key="field.name"
                 :field="field"
                 :form="form"
                 :class="classes[field.name]"
                 v-model="values[field.name]">
          </Field>
        </div>
      </Step>

      <Step :title="$gettext('06. Structure and activity')"
            :validated="step6_valid"
            :open="current_step == 6"
            @stepNext="on_next_step"
            @stepOpen="current_step = 6">

        <div class="row">
          <strong v-translate>Activity (ies) of your travel company</strong>
        </div>

        <div class="row" v-for="(row, index) in step6_fields" :key="index">
          <Field v-for="field in row" :key="field.name"
                 :field="field"
                 :form="form"
                 :class="classes[field.name]"
                 v-model="values[field.name]">
          </Field>
        </div>
      </Step>

      <Step :title="$gettext('07. Upload documents')"
            :validated="step7_valid"
            :open="current_step == 7"
            @stepNext="on_next_step"
            @stepOpen="current_step = 7">
        <div class="row" v-for="(row, index) in step7_fields" :key="index">

          <Field v-for="field in row" :key="field.name"
                 :field="field"
                 :form="form"
                 :class="classes[field.name]"
                 v-model="values[field.name]">
          </Field>
        </div>
      </Step>

      <Step :title="$gettext('08. Signature')"
            :validated="step8_valid"
            :open="current_step == 8"
            :hasNext="false"
            @stepOpen="current_step = 8">
        <div class="row" v-for="(row, index) in step8_fields" :key="index">
          <Field v-for="field in row" :key="field.name"
                 :field="field"
                 :form="form"
                 v-model="values[field.name]">
          </Field>
        </div>
        <div class="row" v-if="!values.confirm_send1 && !values.confirm_send2">
          <div style="color: red" v-translate>Please check both to proceed</div>
        </div>
        <div class="row">
          <div class="form-field">
            <label for="">Signature<span>*</span></label>
            <div class="field">
              <div class="signature__result" @click="signature_popup = true">
                <img :src="values.signature" v-if="values.signature">
                <div v-else class="help" v-translate>Please click for your signature</div>
              </div>
            </div>
          </div>
        </div>
      </Step>

      <div class="row">
        <button class="btn"
                :class="{'btn--disabled': !can_submit, 'btn--primary': can_submit}"
                @click.prevent="submit_form()"
                v-translate>
          Submit request
        </button>
      </div>

      <div v-if="signature_popup">
        <popup>
          <Signature v-model="values.signature"
                     @finish="signature_popup = false"/>
        </popup>
      </div>
      <span class="translation-helper" style="display:none;" v-translate>
          * Travel organization = combination of min. two travel services
        </span>
    </div>
  </form>
</template>

<script>
import _ from 'lodash'
import axios from '../../api'
import Step from '../claimform/Step.vue'
import Field from '../forms/Field.vue'
import Signature from '../Signature.vue'
import {WizardMixin} from '../../mixins/wizaard_forms.js'
import {addValidators, required, minLength, email, sameAs, between, atLeastOneRequired} from "../forms/validation.js"
import popup from '@/components/popup';


export default {
  name: 'MembershipForm',
  mixins: [WizardMixin],
  props: [
    // + WizardMixin: initialForm
  ],
  components: {
    Step,
    Field,
    Signature,
    popup
  },
  data() {
    return {
      // + WizardMixin: current_step, form, values, has_debug
      finished: false,
      classes: {}, // css
      signature_popup: false,
    }
  },
  mounted() {
    addValidators(this.form, this.values, {
      email: [required, email],
      email_repeat: [required, email, sameAs('email')],
      contact_email: [required, email],
      contact_email_repeat: [required, email, sameAs('contact_email')],
      manager_email: [email],
      manager_email_repeat: [email, sameAs('manager_email')],
      company_group_pct: [between(0, 100)],
      how_many_1: [between(0, 99999999)],
      how_many_2: [between(0, 99999999)],
      how_many_3: [between(0, 100000)],
      how_many_4: [between(0, 100000)],
      how_many_5: [between(0, 100000)],
      number_branches: [between(0, 10000)],
      number_franchisees: [between(0, 10000)],
      to_sporadic_activity: [atLeastOneRequired(['reseller_belgian_foreign', 'reseller_belgian', 'retailer_foreign', 'to_main_activity', 'to_secondary_activity', 'to_sporadic_activity'])]
    })
    const translationHelper = this.$el.querySelector('.translation-helper');
    if (translationHelper) {
      const content = translationHelper.textContent.trim();
      const field = this.$el.querySelector('.field-reseller_singular');
      if (field) {
        field.setAttribute('data-content', content);
      }
    }
  },
  computed: {
    // + WizardMixin: formFields

    step1_valid() {
      return this.check_required_fields(this.step1_fields)
    },
    step2_valid() {
      return this.step1_valid && this.check_required_fields(this.step2_fields)
    },
    step3_valid() {
      return this.step2_valid && this.check_required_fields(this.step3_fields)
    },
    step4_valid() {
      return this.step3_valid && this.check_required_fields(this.step4_fields)
    },
    step5_valid() {
      return this.step4_valid && this.check_required_fields(this.step5_fields)
    },
    step6_valid() {
      return this.step5_valid && this.check_required_fields(this.step6_fields) && this.max_step >= 6
    },
    step7_valid() {
      return this.step6_valid && this.check_required_fields(this.step7_fields) && this.max_step >= 7
    },
    step8_valid() {
      return this.step7_valid && this.check_required_fields(this.step8_fields) && Boolean(this.values.signature)
    },

    can_submit() {
      return this.step8_valid
    },

    // fields --------------------------


    step1_fields() {
      return this.row_fields([
        ['statutory_name'],
        ['legal_form', 'language'],
        ['date_of_establishment', 'financial_year_closing_date'],
        ['trade_name'],
      ])
    },

    step2_fields() {
      return this.row_fields([
        ['street', 'number', 'po_box'],
        ['postalcode', 'city', 'country'],
      ])
    },

    step2_commercial_fields() {
      return this.row_fields([
        ['commerc_street', 'commerc_number', 'commerc_po_box'],
        ['commerc_postalcode', 'commerc_city', 'commerc_country'],
      ])
    },


    step3_fields() {
      return this.row_fields([
        ['tel', 'mobile'],
        ['email', 'email_repeat'],
        ['website_url'],
        ['company_number'],
        ['iata_number'],
        ['iban'],
      ])
    },

    step4_fields() {
      return this.row_fields([
        ['contact_title'],
        ['contact_firstname', 'contact_lastname'],
        ['contact_street', 'contact_number', 'contact_po_box'],
        ['contact_postalcode'],
        ['contact_city'],
        ['contact_country'],
        ['contact_tel', 'contact_mobile'],
        ['contact_email', 'contact_email_repeat'],
      ])
    },

    step4_manager_fields() {
      return this.row_fields([
        ['manager_title'],
        ['manager_firstname', 'manager_lastname'],
        ['manager_tel'],
        ['manager_mobile'],
        ['manager_email', 'manager_email_repeat'],
      ])
    },
    step5_fields() {
      return this.row_fields([
        ['accountant_firstname', 'accountant_lastname'],
        ['accountant_company', 'accountant_itaa'],
        ['accountant_street', 'accountant_number'],
        ['accountant_postalcode'],
        ['accountant_city'],
        ['accountant_country'],
        ['accountant_tel', 'accountant_mobile'],
        ['accountant_email'],
        ['accountant_website'],
      ])
    },

    step6_fields() {
      let rows = [
        ['to_main_activity'],
        ['to_secondary_activity'],
        ['to_sporadic_activity'],
        ['reseller_belgian'],
        ['retailer_foreign'],
        ['reseller_singular'],
        // ['reseller_belgian_foreign'],
        // --
        ['company_group'],
        ['company_group_kind'],
        ['company_group_pct'],
        // --
        ['provided_guarantees'],
        ['provided_guarantees_for'],
        ['how_many_1'],
        ['how_many_2'],
        ['how_many_3'],
        ['how_many_4'],
        ['how_many_5'],
        ['number_branches'],
        ['number_franchisees'],
        ['associations'],
        ['payment_arrears_outside'],
        ['payment_arrears_outside_terms'],
        ['payment_agreement_file'],
      ]

      if (this.values.company_group !== true) {
        _.remove(rows, el => el == 'company_group_kind')
        _.remove(rows, el => el == 'company_group_pct')
        this.values.company_group_pct = null
      }
      if (this.values.provided_guarantees !== true) {
        _.remove(rows, el => el == 'provided_guarantees_for')
      }
      if (this.values.payment_arrears_outside !== true) {
        _.remove(rows, el => el == 'payment_arrears_outside_terms')
        _.remove(rows, el => el == 'payment_agreement_file')
      }
      return this.row_fields(rows)
    },


    step7_fields() {
      return this.row_fields([
        ['annual_accounts'],
        ['published_statutes'],
        ['businessplan'],
        ['certificate'],
        ['expected_revenue'],
        ['brochure'],
      ])
    },

    step8_fields() {
      return this.row_fields([
        ['place'],
        ['date'],
        ['confirm_send1'],
        ['confirm_send2'],
      ])
    },

    current_step_fields() {
      const attr = this['step' + this.current_step + '_fields']
      let fields = []
      attr.forEach(row => row.forEach(f => fields.push(f)))
      return fields
    },
    current_step_valid() {
      for (const f of this.current_step_fields) {
        if (this.form.errors[f.name]) {
          return false
        }
      }
      return true
    }

  },
  methods: {
    // + WizardMixin: 
    //      - row_fields(rows)
    //      - check_required_fields(rows)

    on_next_step() {
      this.force_fields_validate(this.current_step_fields)
      this.$nextTick(() => {
        if (this.current_step_valid) {
          this.current_step += 1
          this.scrollToStepStart()
        }
      })
    },
    submit_form(validate) {
      if (!this.can_submit) {
        alert(this.$gettext('Please fill in all the required fiels.'))
        return
      }
      let data = new FormData();
      for (const key in this.values) {
        if (this.values[key] !== null) {
          data.append(key, this.values[key])
        }
      }

      let url = './'
      if (validate) {
        url += '?validate=1'
      }

      axios.post(url, data)
          .then(response => {
            if (!response.data.valid) {
              this.form = response.data.form
              this.find_server_error_step()
            } else {
              this.finished = true
            }
          })
    },

  },

}
</script>

<style scoped>
.field-reseller_singular {
  margin-bottom: 30px;
  position: relative;
}

.field-reseller_singular::after {
  content: attr(data-content);
  font-size: 12px;
  position: absolute;
  top: 100%;
}
</style>