<template>
  <div class="form-field" :class="field_classes">
    <label :for="field_id" v-if="widget_type !== 'checkbox'">
      {{ field.label }}
      <span v-if="field.required">*</span>
    </label>
    <div class="field">
      <checkbox-field v-if="widget_type === 'checkbox'"
                      v-model="widget_value"
                      :name="field.name"
                      :id="field_id"
                      :label="field.label"
      />
      <template v-if="widget_type == 'input'">
        <input
            v-model="widget_value"
            @blur="on_blur"
            :type="field.input_type.type"
            :name="field.name"
            :min="min_value"
            :id="field_id">
      </template>

      <template v-if="widget_type == 'date'">
        <input
            v-model="widget_value"
            @blur="on_blur"
            :type="field.input_type.type"
            :placeholder="$gettext('dd/mm/YYYY')"
            v-mask="'##/##/####'"
            inputmode="numeric"
            :name="field.name"
            :id="field_id">
      </template>

      <template v-if="widget_type == 'textarea'">
          <textarea
              v-model="widget_value"
              @blur="on_blur"
              :name="field.name"
              :id="field_id"/>
      </template>

      <template v-if="widget_type == 'select'">
        <select
            v-model="widget_value"
            @blur="on_blur"
            :name="field.name"
            :id="field_id">
          <option v-for="choice in field.input_type.choices" :value="choice[0]">{{ choice[1] }}</option>
        </select>
      </template>

      <template v-if="widget_type == 'select_multiple'">
        <MultiCheckbox
            v-model="widget_value"
            @blur="on_blur"
            :name="field.name"
            :id="field_id"
            :choices="field.input_type.choices">
        </MultiCheckbox>
      </template>

      <template v-if="widget_type == 'file'">
        <UploadField
            v-model="widget_value"
            @blur="on_blur"
            :max="1"
            :name="field.name"
            :id="field_id"></UploadField>
      </template>

    </div>

    <div v-if="field.help_text" class="help_text">{{ field.help_text }}</div>
    <div v-if="error" class="field-error">{{ error }}</div>
  </div>
</template>

<script>

import MultiCheckbox from './MultiCheckbox.vue'
import UploadField from "./upload_field.vue"
import {ValidateEvents} from "./validation.js"
import CheckboxField from "@/components/forms/CheckboxField";


export default {
  name: 'Field',
  props: [
    'field',
    'form',
    'value',
  ],
  data() {
    return {
      widget_value: this.value,
    }
  },
  computed: {
    widget_type() {
      const input_type = this.field.input_type
      if (input_type.tag == 'select') {
        if (input_type.multiple) {
          return 'select_multiple'
        } else {
          return 'select'
        }
      }
      if (input_type.tag == 'textarea') {
        return 'textarea'
      }
      if (input_type.is_date) {
        return 'date'
      }
      if (input_type.tag == 'input' && input_type.type == 'file') {
        return 'file'
      }
      if (input_type.type === 'checkbox') {
        return 'checkbox'
      }
      return 'input'
    },
    field_id() {
      return 'id_' + this.field.name
    },
    field_classes() {
      let result = 'field-' + this.field.name
      if (this.error) {
        result += ' ' + 'has-error'
      }
      return result
    },
    min_value() {
      if (this.field.input_type.type == 'number') {
        return 0
      } else {
        return ''
      }
    },
    error() {
      return this.form.errors[this.field.name]
    },
  },
  methods: {
    on_blur() {
      ValidateEvents.validate({field: this.field, value: this.widget_value})
    }
  },
  watch: {
    value(val) {
      this.widget_value = val
    },
    widget_value(val) {
      this.$emit('input', this.widget_value)
      ValidateEvents.validate({field: this.field, value: this.widget_value})
    }
  },
  components: {
    CheckboxField,
    MultiCheckbox,
    UploadField,
  }
}
</script>

<style scoped>
.has-error label {
  color: red
}

.field-error {
  color: red;
}

</style>
