<template>
  <div>
    <div class="custom-file-input" @dragover="dragover" @dragleave="dragleave" @drop="drop">
      <span v-if="selectedfile" @click="onUploadClick">
        {{ selectedfile }}
      </span>
      <span v-else class="placeholder" @click="onUploadClick">
        {{ placeholder }}
      </span>
      <button @click.prevent="onUploadClick" class="btn btn--form" v-translate>upload file</button>
      <slot></slot>
    </div>
    <input name="file" @change="onSelected" class="file-input" type="file" ref="file">
  </div>
</template>

<script>
export default {
  props: ['value', 'max'],
  data() {
    return {
      placeholder: this.$gettext("Drag and drop or upload your file"),
      files: null,
    }
  },
  computed: {
    selectedfile() {
      if (this.value) {
        return this.value.name
      }
      return false
    }
  },
  methods: {
    onFileSelected() {
      if (this.max === 1) {
        //this.$emit('input', this.$refs.file.files[0])
        this.$emit('input', this.files[0])
      } else {
        //this.$emit('input', this.$refs.file.files)
        this.$emit('input', this.files)
      }
    },
    onSelected() {
      this.files = this.$refs.file.files
      this.onFileSelected()
    },
    onUploadClick() {
      this.$refs.file.click()
    },
    dragover(e) {
      e.preventDefault()
      if (!this.selectedfile) {
        e.currentTarget.classList.add('dragover')
      }

    },
    dragleave(e) {
      e.currentTarget.classList.remove('dragover')
    },
    drop(e) {
      e.preventDefault()
      if (!this.selectedfile) {
        // triggers the filefield by entering the files
        // this.files = e.dataTransfer.files
        this.files = e.dataTransfer.files
        this.onFileSelected()
        e.currentTarget.classList.remove('dragover')
      }
    }
  },
}
</script>

<style>
.dragover .placeholder {
  border: 3px dashed #000;
  transition: 0.2s all;
}
</style>