import {ValidateEvents} from "../components/forms/validation.js"

export const WizardMixin = {
    props: [
        'initialForm',
    ],
    data() {
        return {
            current_step: 1,
            max_step: 1,
            form: this.initialForm,
            values: this.initialForm.initial,
            has_debug: window.document.location.href.indexOf('debug=') != -1,
        }
    },
    computed: {
        formFields() {
          const fields = {}
          this.form.fields.map((d, index) => {
            fields[d.name] = d
          })
          return fields
        },
    },
    watch: {
        current_step(value) {
            if (value > this.max_step) {
                this.max_step = value
            }
        }
    },
    methods: {
        row_fields(rows) {
            return rows.map(d => {
                return d.map(x => {
                    return this.formFields[x]
                })
            })
        },
        check_required_fields(fields_rows) {
            let result = true
            fields_rows.map(row => {
                row.map(fld => {
                    if (fld.required && !this.values[fld.name]) {
                        result = false
                    }
                })
            })
            return result
        },
        scrollToStepStart() {
            // scrolls to the start of the current step
            const el = this.$el.querySelector(".step--open");
            window.scrollTo(0, el.offsetTop)
        },
        find_server_error_step(){
            setTimeout(() => {
                let steps = this.$el.getElementsByClassName('step')
                for (let i = 0; i < steps.length; i++) {
                    if (steps[i].getElementsByClassName('has-error').length) {
                        this.current_step = i + 1
                        return
                    }
                }
                alert('Please correct validation errors.') // if user sees it - some thing weird happened - like new required field not in frontedn
            }, 100)
        },
        force_fields_validate(fields) {
            fields.forEach(f => {
                ValidateEvents.validate({field: f, value: this.values[f.name]})
            })
        },
        get_form_data() {
            // turns this.values into formData object
            let data = new FormData();
            for (const key in this.values) {
                if (this.values[key] !== null) {
                if (Array.isArray(this.values[key])) {
                    this.values[key].forEach(val => {
                    data.append(key, val)
                    })

                } else {
                    data.append(key, this.values[key])
                }          
                }
            }
            return data
        },
        debug_fill_required() { // for debug
            this.form.fields.forEach(f => {
              if (f.required) {
                if (f.name.indexOf('date') != -1)
                  this.values[f.name] = "23/06/1985"
                else if (f.name.indexOf('email') != -1)
                  this.values[f.name] = "test@test.com"
                else
                  this.values[f.name] = "1"
              }
            })
            this.values.signature = ''
          }
    },
}
