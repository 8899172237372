import Vue from 'vue'


// -------- i18n------------------------------------------------
import GetTextPlugin from 'vue-gettext'

// Language switching is backend based.
// "django.catalog" comes from backend with actual language
const translations = {
  'en_US': django.catalog,
}

Vue.use(GetTextPlugin, {translations: translations, silent: true})
Vue.config.productionTip = false

// -------------- components -------------------------------------


import ClaimForm from './components/claimform/ClaimForm.vue'
import MemberSearch from './components/members/MemberSearch.vue'
import MembershipForm from './components/membershipform/MembershipForm.vue'
import B2BMembershipForm from './components/membershipform/B2BMembershipForm.vue'

import VueMask from 'v-mask'
Vue.use(VueMask);


function mount_component(component, element, props) {
  //let store = createStore()
  new Vue({
    //store,
    render: h => h(component, {props: props || {}})
  }).$mount(element)
}

// Exports to browser:

window.mount_component = mount_component
window.components = {
  ClaimForm,
  MemberSearch,
  MembershipForm,
  B2BMembershipForm,

}
