<template>
  <div class="field-checkbox" @click="check" tabindex="0" @keypress.space.prevent="check">
    <div class="checkbox" :class="{'checkbox--active': value}"></div>
    <div class="label" v-html="label"></div>
  </div>
</template>

<script>
export default {
  props: ['value', 'label'],
  methods: {
    check() {
      if (this.value) {
        this.$emit('input', false)
      } else {
        this.$emit('input', true)
      }
    }
  }
}
</script>

